import { render, staticRenderFns } from "./imagectaDefault.vue?vue&type=template&id=26896502&"
import script from "./imagectaDefault.vue?vue&type=script&lang=js&"
export * from "./imagectaDefault.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Kirbyimage: require('/var/www/osch/frontend/components/kirbyimage.vue').default,Kirbylink: require('/var/www/osch/frontend/components/kirbylink.vue').default})
